<template>
  <div>
    <h4>Add Agency</h4>
    <div class="card">
      <div class="card-body">
        <loading v-if="loading" />
        <form @submit.prevent="submitAgency" v-if="! loading">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Name</label>
                <input
                  type="text"
                  placeholder="Agency Name"
                  v-model="agency.name"
                  required
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Registration Certificate</label>
                <input
                  type="file"
                  placeholder="Registration Certificate"
                  ref="registration"
                  required
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Approval Letter</label>
                <input
                  type="file"
                  placeholder="Approval Letter"
                  ref="approval"
                  required
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Logo</label>
                <input
                  type="file"
                  placeholder="Agency Logo"
                  ref="logo"
                  required
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Email</label>
                <input
                  type="text"
                  placeholder="Agency Email"
                  v-model="agency.email"
                  required
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Phone Number</label>
                <input
                  type="text"
                  placeholder="Agency Phone Number"
                  v-model="agency.phone_number"
                  required
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Physical Address</label>
                <input
                  type="text"
                  placeholder="Physical Address"
                  v-model="agency.address"
                  class="form-control"
                  required
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>About Agency</label>
                <textarea class="form-control" required v-model="agency.about" placeholder="About"></textarea>
              </div>
            </div>
            <div class="col-md-12">
              <h6>Online Presence</h6>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Website <small class="text-muted">*optional</small></label>
                <input
                  type="text"
                  placeholder="Website"
                  v-model="agency.socials.website"
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Facebook <small class="text-muted">*optional</small></label>
                <input
                  type="text"
                  placeholder="Facebook"
                  v-model="agency.socials.facebook"
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Twitter <small class="text-muted">*optional</small></label>
                <input
                  type="text"
                  placeholder="Twitter"
                  v-model="agency.socials.twitter"
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Instagram <small class="text-muted">*optional</small></label>
                <input
                  type="text"
                  placeholder="Instagram"
                  v-model="agency.socials.instagram"
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Youtube <small class="text-muted">*optional</small></label>
                <input
                  type="text"
                  placeholder="Youtube"
                  v-model="agency.socials.youtube"
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Tiktok <small class="text-muted">*optional</small></label>
                <input
                  type="text"
                  placeholder="Tiktok"
                  v-model="agency.socials.tiktok"
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <button class="btn btn-primary">
                  <i class="lni lni-plus me-2"></i>Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      agency: {
        name: '',
        email: '',
        phone_number: '',
        address: '',
        about: '',
        socials: {
          website: '',
          facebook: '',
          twitter: '',
          instagram: '',
          youtube: '',
          tiktok: ''
        } 
      },
      loading: false
    };
  },

  methods: {
    submitAgency() {
      this.loading = true

      let data = new FormData();
      data.append("name", this.agency.name);
      data.append("logo", this.$refs.logo.files[0]);
      data.append("registration", this.$refs.registration.files[0]);
      data.append("approval", this.$refs.approval.files[0]);
      data.append("email", this.agency.email);
      data.append("phone_number", this.agency.phone_number);
      data.append("address", this.agency.address);
      data.append("about", this.agency.about);
      data.append("socials[website]", this.agency.socials.website);
      data.append("socials[facebook]", this.agency.socials.facebook);
      data.append("socials[twitter]", this.agency.socials.twitter);
      data.append("socials[instagram]", this.agency.socials.instagram);
      data.append("socials[youtube]", this.agency.socials.youtube);
      data.append("socials[tiktok]", this.agency.socials.tiktok);

      this.$axios
        .post("/api/v1/my-account/agencies", data)
        .then((response) => {
          this.loading = false
          this.$router.push({
            name: "page",
            params: { slug: response.data.agency.slug },
          });
        });
    },
  },
};
</script>
