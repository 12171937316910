<template>
  <div>
    <loading v-if="is_loading" />

    <div class="row mb-3" v-if="agency.role == 'owner'">
      <div class="col-md-12">
        <a
          href="#"
          @click.prevent="deleteAgency"
          class="btn btn-danger btn-sm float-end"
          ><i class="lni lni-trash-can me-2"></i>Delete Agency</a
        >
        <router-link
          class="btn btn-info btn-sm me-2 float-end"
          :to="{
            name: 'page',
            params: { slug: agency.slug },
          }"
          ><i class="lni lni-eye me-2"></i>View</router-link
        >
      </div>
    </div>

    <div v-if="!is_loading" class="dashboard-wraper">
      <button
        :class="`float-end btn btn-${
          agency.status == 'pending'
            ? 'warning'
            : agency.status == 'approved'
            ? 'success'
            : 'danger'
        }`"
      >
        {{ agency.status }}
      </button>
      <h4 class="mb-4">Agency Information</h4>
      <form @submit.prevent="updateAgency">
        <div class="row">
          <div class="col-md-12 mb-3">
            <label>Name</label>
            <input
              type="text"
              placeholder="Agency Name"
              v-model="agency.name"
              required
              class="form-control"
              :readonly="agency.role == 'member'"
            />
          </div>
          <div class="col-md-4 mb-3">
            <div class="border p-3">
              <p>Logo</p>
              <img :src="agency.logo" :alt="agency.name" height="80" />
              <input
                type="file"
                placeholder="Upload New Logo"
                ref="agency_logo"
                class="form-control mt-2"
                v-if="agency.role == 'owner'"
              />
            </div>
          </div>
          <div class="col-md-4 mb-3" v-if="agency.role == 'owner'">
            <div class="border p-3">
              <p>Agency Registration</p>
              <a :href="agency.registration" target="_blank" class="text-danger"
                ><i class="lni lni-download me-2"></i> Download Document</a
              >
              <input
                type="file"
                placeholder="Upload New Document"
                ref="registration"
                class="form-control mt-3"
              />
            </div>
          </div>
          <div class="col-md-4 mb-3" v-if="agency.role == 'owner'">
            <div class="border p-3">
              <p>Approval Letter</p>
              <a :href="agency.approval" target="_blank" class="text-danger"
                ><i class="lni lni-download me-2"></i> Download Document</a
              >
              <input
                type="file"
                placeholder="Upload New Document"
                ref="approval"
                class="form-control mt-3"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 mb-3">
            <label>Email</label>
            <input
              type="email"
              placeholder="Agency Email"
              v-model="agency.email"
              required
              class="form-control"
              :readonly="agency.role == 'member'"
            />
          </div>
          <div class="col-md-6 mb-3">
            <label>Phone Number</label>
            <input
              type="text"
              placeholder="Agency Phone Number"
              v-model="agency.phone_number"
              required
              class="form-control"
              :readonly="agency.role == 'member'"
            />
          </div>
          <div class="col-md-6 mb-3">
            <label>Whatsapp Phone Number</label>
            <input
              type="text"
              placeholder="Agency Whatsapp Phone Number"
              v-model="agency.whatsapp"
              required
              class="form-control"
              :readonly="agency.role == 'member'"
            />
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Physical Address</label>
              <input
                type="text"
                placeholder="Physical Address"
                v-model="agency.address"
                class="form-control"
                required
                :readonly="agency.role == 'member'"
              />
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label>About Agency</label>
              <textarea
                class="form-control"
                required
                v-model="agency.about"
                placeholder="About"
                :readonly="agency.role == 'member'"
              ></textarea>
            </div>
          </div>
          <div class="col-md-12">
            <h6>Online Presence</h6>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Website <small class="text-muted">*optional</small></label>
              <input
                type="text"
                placeholder="Website"
                v-model="agency.socials.website"
                class="form-control"
                :readonly="agency.role == 'member'"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label
                >Facebook <small class="text-muted">*optional</small></label
              >
              <input
                type="text"
                placeholder="Facebook"
                v-model="agency.socials.facebook"
                class="form-control"
                :readonly="agency.role == 'member'"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Twitter <small class="text-muted">*optional</small></label>
              <input
                type="text"
                placeholder="Twitter"
                v-model="agency.socials.twitter"
                class="form-control"
                :readonly="agency.role == 'member'"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label
                >Instagram <small class="text-muted">*optional</small></label
              >
              <input
                type="text"
                placeholder="Instagram"
                v-model="agency.socials.instagram"
                class="form-control"
                :readonly="agency.role == 'member'"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Youtube <small class="text-muted">*optional</small></label>
              <input
                type="text"
                placeholder="Youtube"
                v-model="agency.socials.youtube"
                class="form-control"
                :readonly="agency.role == 'member'"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Tiktok <small class="text-muted">*optional</small></label>
              <input
                type="text"
                placeholder="Tiktok"
                v-model="agency.socials.tiktok"
                class="form-control"
                :readonly="agency.role == 'member'"
              />
            </div>
          </div>
          <div class="col-md-12" v-if="agency.role == 'owner'">
            <button class="btn btn-primary">
              <i class="lni lni-save me-2"></i>Save
            </button>
          </div>
        </div>
      </form>
    </div>
    <div v-if="!is_loading" class="dashboard-wraper mt-4">
      <a
        href="#"
        @click.prevent="show_add_user = true"
        v-if="agency.role == 'owner'"
        class="btn btn-sm btn-primary float-end"
        ><i class="lni lni-plus me-2"></i>Add New</a
      >
      <h4>Agency Members</h4>
      <table class="table table-striped">
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Email</th>
            <th>Phone Number</th>
            <th>Role</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(user, i) in agency.users" :key="`user-${i}`">
            <td>{{ i + 1 }}</td>
            <td>{{ user.name }}</td>
            <td>{{ user.email }}</td>
            <td>{{ user.phone_number }}</td>
            <td>{{ user.role }}</td>
            <td>
              <a
                href="#"
                @click.prevent="removeUser(user)"
                v-if="user.role == 'member' && agency.role == 'owner'"
                ><i class="lni lni-trash-can me-2"></i>Remove</a
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <modal :show="show_add_user" @close="show_add_user = false">
      <template slot="header">Add User to agency</template>
      <form @submit.prevent="addUser" v-if="! inviting_user">
        <div class="form-group">
          <label>Email</label>
          <input
            type="email"
            placeholder="User's email address"
            v-model="new_user.email"
            required
            class="form-control"
          />
        </div>
        <div class="form-group text-end">
          <button class="btn btn-primary">
            <i class="lni lni-save me-2"></i>Save
          </button>
        </div>
      </form>
      <loading v-if="inviting_user" />
    </modal>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      agency: {
        name: '',
        email: '',
        phone_number: '',
        address: '',
        about: '',
        socials: {
          website: '',
          facebook: '',
          twitter: '',
          instagram: '',
          youtube: '',
          tiktok: ''
        } 
      },
      is_loading: true,
      inviting_user: false,
      show_add_user: false,
      new_user: {},
    };
  },

  methods: {
    fetch() {
      this.is_loading = true;

      this.$axios
        .get(`/api/v1/my-account/agencies/${this.$route.params.id}`)
        .then((response) => {
          this.agency = response.data.agency;
          this.is_loading = false;
        });
    },

    addUser() {
      this.inviting_user = true
      this.$axios
        .post(
          `/api/v1/my-account/agencies/${this.$route.params.id}/add-user`,
          this.new_user
        )
        .then(() => {
          this.fetch();
          this.inviting_user = false
          this.show_add_user = false;
        }).catch(() => {
          this.inviting_user = false
        });
    },

    removeUser(user) {
      this.is_loading = true;

      let data = {
        user_id: user.id,
      };

      this.$axios
        .post(
          `/api/v1/my-account/agencies/${this.$route.params.id}/remove-user`,
          data
        )
        .then(() => {
          this.is_loading = false;
          this.fetch();
        });
    },

    updateAgency() {
      this.is_loading = true

      let data = new FormData();
      data.append("name", this.agency.name);
      data.append("email", this.agency.email);
      data.append("phone_number", this.agency.phone_number);
      data.append("whatsapp", this.agency.whatsapp);
      data.append("address", this.agency.address);
      data.append("about", this.agency.about);
      data.append("socials[website]", this.agency.socials.website);
      data.append("socials[facebook]", this.agency.socials.facebook);
      data.append("socials[twitter]", this.agency.socials.twitter);
      data.append("socials[instagram]", this.agency.socials.instagram);
      data.append("socials[youtube]", this.agency.socials.youtube);
      data.append("socials[tiktok]", this.agency.socials.tiktok);

      let logo = this.$refs.agency_logo.files[0];
      if (logo) {
        data.append("logo", logo);
      }

      if(this.$refs.registration) {
        let registration = this.$refs.registration.files[0];

        if (registration) {
          data.append("registration", registration);
        }
      }
      
      if(this.$refs.approval) {
        let approval = this.$refs.approval.files[0];

        if (approval) {
          data.append("approval", approval);
        }
      }
      
      this.$axios
        .post(`/api/v1/my-account/agencies/${this.$route.params.id}`, data)
        .then(() => {
          this.fetch();
          this.is_loading = false
          window.scroll(0, 0)
        });
    },

    deleteAgency() {
      this.$axios
        .delete(`/api/v1/my-account/agencies/${this.$route.params.id}`)
        .then(() => {
          this.$router.push({ name: "my-account.home" });
        });
    },
  },
};
</script>
